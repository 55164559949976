import React from "react"

import "./Nav.styles.scss"

export const Nav = () => {
  return (
    <nav className="Nav">
      <ul className="Nav__List">
        <li className="Nav__Item">
          <a href="#about">About</a>
        </li>
        <li className="Nav__Item">
          <a href="#music">Music</a>
        </li>
        <li className="Nav__Item">
          <a href="#live">Live</a>
        </li>
      </ul>
    </nav>
  )
}
