import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"

import { Nav } from "../Nav/Nav.component"

import "./Banner.styles.scss"

export const Banner = React.forwardRef(function Banner(props, ref) {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      imageSharp {
        id
      }
      file(relativePath: { eq: "vic-bellevue-picnic.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const bgImageData = data.file.childImageSharp.fluid

  return (
    <div className="Banner" ref={ref}>
      <header className="Banner__Header">
        <h1 className="Banner__Title">VICTOR BEACH</h1>
      </header>
      <Nav />
      <div className="Banner__Photo">
        <BackgroundImage
          Tag="div"
          className="Bio__BackgroundImage"
          fluid={bgImageData}
          style={{
            width: "100%",
            minHeight: "100vh",
            height: "100%",
          }}
          backgroundColor={`#040e18`}
        />
      </div>
    </div>
  )
})
